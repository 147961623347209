import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type Visit } from '../types/Visit'
import { type ImportChart } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetVisit = fetcher
  .path('/api/Visit/{visitId}')
  .method('get')
  .create()

export const getVisit = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    return (await setupGetVisit({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetVisit.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetVisits = fetcher.path('/api/Visit').method('get').create()

export const getVisits = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    return (await setupGetVisits({})).data
  } catch (error) {
    if (error instanceof setupGetVisits.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetVisitsByPatient = fetcher
  .path('/api/Visit/Patient/{patientId}')
  .method('get')
  .create()

export const getVisitsByPatient = async (
  patientId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    return (await setupGetVisitsByPatient({ patientId })).data
  } catch (error) {
    if (error instanceof setupGetVisitsByPatient.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/Visit')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewVisit = async (
  Visit: Visit,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    return (await createUser(Visit)).data
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteVisit = fetcher
  .path('/api/Visit/{visitId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteVisit = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    await setupDeleteVisit({ visitId })
  } catch (error) {
    if (error instanceof setupGetVisit.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateVisit = fetcher
  .path('/api/Visit/{visitId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateVisit = async (
  visitId: number,
  Visit: Visit,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    await setupUpdateVisit({ visitId, ...Visit })
  } catch (error) {
    if (error instanceof setupUpdateVisit.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
    }
  }
}

const setupGetSchedule = fetcher
  .path('/api/Visit/{visitDate}/Clinic/{clinicId}')
  .method('get')
  .create()

export const getSchedule = async (
  visitDate: string,
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    return (await setupGetSchedule({ visitDate, clinicId })).data.sort(
      (a: any, b: any) => {
        return a.arrivalDateTime > b.arrivalDateTime ? 1 : -1
      }
    )
  } catch (error) {
    if (error instanceof setupGetVisits.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetVisitAssessmentPlan = fetcher
  .path('/api/Visit/{vId}/VisitAssessmentPlan')
  .method('get')
  .create()

export const getVisitAssessmentPlan = async (
  vId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    return (await setupGetVisitAssessmentPlan({ vId })).data
  } catch (error) {
    if (error instanceof setupGetVisitAssessmentPlan.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupUpdateAddendum = fetcher
  .path('/api/Visit/{vId}/Addendum')
  .method('put')
  .create({ 'api-version': undefined })

export const updateAddendum = async (
  vId: number,
  visit: Visit,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin
          : 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    await setupUpdateAddendum({ vId, ...visit })
  } catch (error) {
    if (error instanceof setupUpdateAddendum.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
    }
  }
}

const setupImportChart = fetcher
  .path('/api/Visit/{visitId}/FieldImport/{sourceId}')
  .method('put')
  .create({ 'api-version': undefined })

export const importChart = async (
  visitId: number,
  sourceId: number,
  importChartRequest: ImportChart,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin
          : 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    await setupImportChart({ visitId, sourceId, ...importChartRequest })
  } catch (error) {
    if (error instanceof setupImportChart.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
    }
  }
}
