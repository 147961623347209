import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type Coding } from '../types/Coding'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetCoding = fetcher
  .path('/api/VisitCoding/{vId}')
  .method('get')
  .create()

export const getCoding = async (vId: number, getToken: any): Promise<any> => {
  if (vId === undefined) return

  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetCoding({ vId })).data
  } catch (error) {
    if (error instanceof setupGetCoding.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateCoding = fetcher
  .path('/api/VisitCoding/{vId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateCoding = async (
  vId: number,
  chiefCoding: Coding,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdateCoding({ vId, ...chiefCoding })
  } catch (error) {
    if (error instanceof setupGetCoding.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

// AI Generated code
const setupGetAICoding = fetcher
  .path('/api/DocumentId/AiVisitSummary/EMCode/{visitId}')
  .method('get')
  .create()

export const getAICoding = async (visitId: number, getToken: any): Promise<any> => {
  if (visitId === undefined) return

  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string
          }`,
      },
    },
  })
  try {
    const response = (await setupGetAICoding({ visitId })).data
    return response
  } catch (error) {
    if (error instanceof setupGetAICoding.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

