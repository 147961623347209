import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import { getAICoding, getCoding, updateCoding } from '@/services'
import { type Coding } from '@/types/Coding'

interface VisitCodingState {
  visitCoding: Coding | null;
  setVisitCoding: (visitCoding: Coding) => void;
}

export const useVisitCodingStore = create<VisitCodingState>()(
  devtools((set) => ({
    visitCoding: null,
    setVisitCoding: (coding: Coding) => set({ visitCoding: coding }),
  }))
)

export const useQueryGetCoding = (
  vId: number,
  getToken: any
): QueryObserverResult<Coding, unknown> => {
  const { setVisitCoding } = useVisitCodingStore()

  return useQuery<Coding>(
    ['visit-coding', vId],
    async () => {
      const coding = await getCoding(vId, getToken)
      return coding || {}
    },
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setVisitCoding(data)
      },
    }
  )
}

export const useMutateUpdateCoding = (
  getToken: any
): UseMutationResult<Coding, unknown, { vId: number; chiefCoding: Coding }> => {
  return useMutation<Coding, unknown, { vId: number; chiefCoding: Coding }>(
    async ({ vId, chiefCoding }) =>
      await updateCoding(vId, chiefCoding, getToken),
    {
      onSuccess: (data) => {},
    }
  )
}

export const useQueryGetAICoding = (
  vId: number,
  getToken: any
): QueryObserverResult<string, unknown> => {
  return useQuery<string>(
    ['ai-coding', vId],
    async () => {
      try {
        const coding = await getAICoding(vId, getToken)
        return coding || ''
      } catch (error) {
        console.error('Error fetching coding:', error)
        throw error // Rethrow the error to allow react-query to handle it
      }
    }
  )
}
