import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getProcedure,
  updateProcedure,
  editProcedure,
  type Procedure,
  type CPTModifier,
  type AccountUser,
  type ProcedureType,
  type ProcedureCPTCode
} from '@urgentiq/typesservices'
import { type Subsection, type Summary } from '@/types'
import { formatInLocal, isEmpty } from '@/utility'
import { format } from 'date-fns'
import { type GetToken } from '@clerk/types'
import { buildCPTSections } from '@/helpers'

interface ProcedureState {
  procedure: Procedure | null;
  setProcedure: (procedure: Procedure) => void;
  getProcedureSummary: (getToken: GetToken, procedureTypes: ProcedureType[], CPTModifiers: CPTModifier[], users: AccountUser[]) => Promise<Summary>
}

export const useProcedureStore = create<ProcedureState>()(
  devtools((set, get) => ({
    procedure: null,
    setProcedure: (procedure: Procedure) => set({ procedure }),
    getProcedureSummary: async (getToken: GetToken, procedureTypes: ProcedureType[], CPTModifiers: CPTModifier[], users: AccountUser[]) => {
      const { procedure } = get()
      const buildProcedures = async (): Promise<Subsection> => {
        const proceduresSummary: any[] = []
        let consentString = ''

        const formatDate = (): string => {
          if (
            isEmpty(procedure?.entireSectionUpdatedAt) ||
            procedure?.entireSectionUpdatedAt === '0001-01-01T00:00:00+00:00'
          ) {
            return format(new Date(Date.now()), 'h:mm a')
          } else {
            return format(
              new Date(formatInLocal(procedure?.entireSectionUpdatedAt ?? '')),
              'h:mm a'
            )
          }
        }

        if (procedure === null) {
          consentString = 'None'
        } else if (
          (procedure?.writtenConsent === undefined ||
            procedure?.writtenConsent === null) &&
          (procedure?.verbalConsent === undefined ||
            procedure?.verbalConsent === null)
        ) {
          consentString = 'None'
        } else if (procedure?.writtenConsent! && procedure?.verbalConsent!) {
          consentString = 'Verbal and Written'
        } else if (procedure?.verbalConsent!) {
          consentString = 'Verbal'
        } else if (procedure?.writtenConsent!) {
          consentString = 'Written'
        } else {
          consentString = 'None'
        }

        if (procedure !== null) {
          if (
            procedure?.writtenConsent !== undefined ||
            procedure?.verbalConsent !== undefined
          ) {
            proceduresSummary.push({ Consent: `${consentString}` })
          }
          if (
            procedure?.visitClinicalProcedureList !== null &&
            procedure?.visitClinicalProcedureList !== undefined
          ) {
            await buildCPTSections(
              procedure?.visitClinicalProcedureList ?? [],
              proceduresSummary,
              getToken,
              procedureTypes,
              CPTModifiers
            ).catch((error) => {
              throw error
            })
          }
          if (!isEmpty(procedure?.details)) {
            proceduresSummary.push({ Details: `${procedure?.details ?? ''}` })
          }
          if (!isEmpty(procedure?.results)) {
            proceduresSummary.push({ Results: `${procedure?.results ?? ''}` })
          }
          if (
            procedure?.providerAccountUserId !== null &&
            procedure?.providerAccountUserId !== undefined &&
            procedure?.providerAccountUserId !== 0
          ) {
            proceduresSummary.push({
              Provider: `${
                users?.find((user) => user.id === procedure?.providerAccountUserId)
                  ?.firstName ?? ''
              } ${
                users?.find((user) => user.id === procedure?.providerAccountUserId)
                  ?.lastName ?? ''
              } ${formatDate() === '' ? '' : `(${formatDate()})`}`,
            })
          }
          proceduresSummary.push({
            'Sent Orders': '',
          })
        }

        return { name: '', value: proceduresSummary }
      }

      const sections = await buildProcedures()
      const ProcedureSummary: Summary = {
        sectionName: 'Orders & Procedures',
        sections: [sections],
      }

      return ProcedureSummary
    },
  }))
)

export const useQueryGetProcedure = (
  vId: number,
  getToken: any
): QueryObserverResult<Procedure, unknown> => {
  const { setProcedure } = useProcedureStore()
  return useQuery<Procedure>(
    ['procedure', vId],
    async () => (await getProcedure(vId, getToken)) || {},
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setProcedure(data)
      },
    }
  )
}

export const useMutateUpdateProcedure = (
  getToken: any
): UseMutationResult<
  Procedure,
  unknown,
  { vId: number; chiefProcedure: Procedure }
> => {
  const { setProcedure } = useProcedureStore()
  return useMutation<
    Procedure,
    unknown,
    { vId: number; chiefProcedure: Procedure }
  >(
    async ({ vId, chiefProcedure }) => {
      await updateProcedure(vId, chiefProcedure, getToken)
      return await getProcedure(vId, getToken)
    },
    {
      onSuccess: (updatedProcedure) => {
        setProcedure(updatedProcedure)
      },
    }
  )
}

export const useMutateUpdateIndividualProcedure = (
  getToken: any
): UseMutationResult<
  ProcedureCPTCode,
  unknown,
  { visitClinicalProcedureId: number; chiefProcedure: ProcedureCPTCode }
> => {
  const { procedure, setProcedure } = useProcedureStore()
  return useMutation<
    ProcedureCPTCode,
    unknown,
    { visitClinicalProcedureId: number; chiefProcedure: ProcedureCPTCode }
  >(
    async ({ visitClinicalProcedureId, chiefProcedure }) =>
      await editProcedure(visitClinicalProcedureId, chiefProcedure, getToken),
    {
      onSuccess: (updatedProcedure) => {
        const updatedVisitClinicalProcedureList = procedure?.visitClinicalProcedureList?.map(
          (procedure: any) =>
            procedure.id === updatedProcedure.id ? updatedProcedure : procedure
        )
        setProcedure({
          ...procedure,
          visitClinicalProcedureList: updatedVisitClinicalProcedureList,
        })
      },
    }
  )
}
