import { type DoseSpotMedicationResults } from '@/types/DoseSpotMedicationResult'
import { type DoseSpotToken } from '@/types/DoseSpotToken'

export const findMedications = async (
  searchText: string,
  token: DoseSpotToken
): Promise<any> => {
  const url = `https://my.dosespot.com/webapi/v2/medications/search?name=${
    searchText ?? ''
  }`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token.accessToken!}`,
    },
  })
  return response.json() as unknown as DoseSpotMedicationResults
}
