import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  type QueryObserverResult,
  type UseMutationResult,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type { GetToken } from '@clerk/types'
import { type TaskPage, type Task , type ITaskTableData , type AccountUser, type Patient } from '@urgentiq/typesservices'
import {
  addNewTask,
  deleteTask,
  getAllTasks,
  getTask,
  updateTask,
  getPatientTasks,
  getTaskFilter
} from '@urgentiq/typesservices'

export enum TabTitles {
  ALL = 'All',
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  MY_TASKS = 'My Tasks'
}

const taskToTableRow = (task: Task, accountUsers: AccountUser[], patients: Patient[]): ITaskTableData => {
  const {
    id,
    dateAssigned,
    dateCompleted,
    dateDue,
    title,
    notes,
    priorityId = 1,
    documentId,
    createdById,
    typeId = 1,
    statusId = 1,
  } = task

  return {
    id: id ?? -1,
    typeId,
    statusId,
    notes: notes ?? '',
    title,
    account: accountUsers.find(({ id }) => id === task.accountUserId) ?? {},
    creator: accountUsers.find(({ id }) => id === createdById) ?? {},
    patientDetails: patients.find(({ id }) => id === task.patientId) ?? {},
    documentId: documentId ?? undefined,
    dateAssigned,
    dateCompleted,
    dateDue,
    priorityId
  }
}

export enum TaskStatus {
  UNDEFINED,
  NEW,
  IN_PROGRESS,
  COMPLETED
}

const tabFilters = new Map<TabTitles, (task: Task, userId?: number) => boolean>([
  [TabTitles.ALL, (task) => task.statusId !== TaskStatus.COMPLETED],
  [TabTitles.NEW, (task) => task.statusId === TaskStatus.NEW],
  [TabTitles.IN_PROGRESS, (task) => task.statusId === TaskStatus.IN_PROGRESS],
  [TabTitles.COMPLETED, (task) => task.statusId === TaskStatus.COMPLETED],
  [TabTitles.MY_TASKS, (task, userId) => task.statusId !== TaskStatus.COMPLETED && task.accountUserId === userId]
])

interface TaskState {
  tasks: Task[];
  isLoading: boolean
  taskPage: TaskPage
  setTasks: (schedules: Task[]) => void
  setTaskPage: (taskPage: TaskPage) => void
  setIsLoading: (isLoading: boolean) => void
  getCountForTab: (status: TabTitles, userId?: number) => number
  getTasksForTab: (status: TabTitles, accountUsers?: AccountUser[], patients?: Patient[], userId?: number) => ITaskTableData[]
}

export const useTasksStore = create<TaskState>()(
  devtools(
    persist(
      (set, get) => ({
        tasks: [],
        isLoading: false,
        taskPage: {},
        setTasks: (tasks: Task[]) => set({ tasks }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setTaskPage: (taskPage: TaskPage) => set({ taskPage }),
        getCountForTab: (status: TabTitles, userId?: number): number => {
          const { taskPage } = get()
          const filter = tabFilters.get(status)
          if (!filter) {
            return 0
          }
          return taskPage?.items ? taskPage?.items.filter((task: Task) => filter(task, userId)).length ?? 0 : 0
        },
        getTasksForTab: (status: TabTitles, accountUsers: AccountUser[] = [], patients: Patient[] = [], userId?: number): ITaskTableData[] => {
          const { taskPage } = get()
          const filter = tabFilters.get(status)
          if (!filter) {
            return []
          }
          return taskPage?.items ? taskPage?.items.filter((task: Task) => filter(task, userId)).map((task: Task) => taskToTableRow(task, accountUsers, patients)) : []
        },
      }),
      {
        name: 'internal-tasks',
      }
    )
  )
)

export const useQueryGetAllTasks = (
  getToken: GetToken
): QueryObserverResult<Task[], unknown> => {
  const { setTasks, setIsLoading } = useTasksStore()

  return useQuery<Task[]>(
    ['task-management'],
    async () => {
      setIsLoading(true)
      return await getAllTasks(getToken)
    },
    {
      onSuccess: (data) => {
        setTasks(data ?? [])
        setIsLoading(false)
      },
      onError: (error) => {
        setIsLoading(false)
        throw error
      },
      initialData: [],
    }
  )
}

export const useMutateAddNewTask = (
  getToken: any
): UseMutationResult<Task, unknown, { task: Task }> => {
  const { tasks, setTasks, taskPage, setTaskPage } = useTasksStore()

  return useMutation<Task, unknown, { task: Task }>(async ({ task }) => {
    const response = await addNewTask(task, getToken)
    const data = (await getTask(response.id ?? 0, getToken)).data
    setTasks([...tasks, data])
    const newTaskPage = { ...taskPage }
    newTaskPage.items = [...newTaskPage?.items ?? [], data]
    setTaskPage(newTaskPage)
    return data
  })
}

export const useMutateDeleteTask = (
  getToken: any
): UseMutationResult<Task, unknown, number> => {
  const { tasks, setTasks, taskPage, setTaskPage } = useTasksStore()

  return useMutation<Task, unknown, number>(
    async (id: number) => {
      await deleteTask(id, getToken)
      return { id }
    },
    {
      onSuccess: ({ id }: Task) => {
        const newTasks = tasks.filter((task: Task) => task?.id !== id)
        setTasks(newTasks)
        const newTaskPage = { ...taskPage }
        newTaskPage.items = newTaskPage?.items?.filter((task: Task) => task?.id !== id)
        setTaskPage(newTaskPage)
      },
    }
  )
}

interface UpdateTaskMutationPayload {
  id: number;
  task: Partial<Task>;
}

export const useMutateUpdateTask = (
  getToken: any
): UseMutationResult<Task, unknown, { id: number; task: Task }> => {
  const { tasks, setTasks, taskPage, setTaskPage } = useTasksStore()

  return useMutation<Task, unknown, UpdateTaskMutationPayload>(
    async ({ id, task: partialTask }) => {
      const task: Task = taskPage?.items?.find((item: Task) => item.id === id) ?? {}
      const newTask = { ...task, ...partialTask }
      const newTasks = tasks.map((t) => {
        if (t.id === id) {
          return { ...task, ...partialTask }
        }
        return t
      })
      setTasks(newTasks)
      const newTaskPage = { ...taskPage }
      newTaskPage.items = newTaskPage?.items?.map((t: Task) => {
        if (t.id === id) {
          return { ...task, ...partialTask }
        }
        return t
      })
      setTaskPage(newTaskPage)
      return await updateTask(id, newTask, getToken)
    }
  )
}

export const useQueryPaginateTasks = (
  getToken: GetToken,
  userAccountId?: number,
  clinicId?: number,
  patientId?: number,
  visitId?: number,
  taskStatusId?: number,
  taskTypeId?: number,
  taskPriorityId?: number,
  titleFilter?: string,
  dueDateFrom?: string,
  dueDateTo?: string,
  sortColumn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8,
  sortAscending?: boolean,
  pageNumber?: number,
  pageSize?: number
): QueryObserverResult<TaskPage, unknown> => {
  const { setTaskPage, setIsLoading } = useTasksStore()

  return useQuery<TaskPage>(
    ['task-management', userAccountId, clinicId, visitId, taskTypeId, taskPriorityId, pageNumber
      , pageSize, patientId, taskStatusId, sortColumn, sortAscending],
    async () => {
      setIsLoading(true)
      return await getTaskFilter(getToken, userAccountId, clinicId, patientId, visitId, taskTypeId, taskStatusId, taskPriorityId, titleFilter, dueDateFrom, dueDateTo, sortColumn, sortAscending, pageNumber, pageSize)
    }
    ,
    {
      onSuccess: (data) => {
        setTaskPage(data ?? {})
        setIsLoading(false)
      },
      onError: (error) => {
        setIsLoading(false)
        throw error
      },
      initialData: {},
    }
  )
}

export const useQueryGetPatientTasks = (
  getToken: GetToken,
  patientId?: number
): QueryObserverResult<Task[], unknown> => {
  const { setTasks, setIsLoading } = useTasksStore()

  return useQuery<Task[]>(
    ['task-management', patientId],
    async () => {
      setIsLoading(true)
      if (!patientId) {
        return await getAllTasks(getToken)
      } else {
        return await getPatientTasks(patientId, getToken)
      }
    },
    {
      onSuccess: (data) => {
        setTasks(data ?? [])
        setIsLoading(false)
      },
      onError: (error) => {
        setIsLoading(false)
        throw error
      },
      initialData: [],
    }
  )
}
