import {
  type QueryObserverResult,
  type UseMutationResult,
  useQuery,
  useMutation
} from '@tanstack/react-query'
import {
  createCompany,
  deleteCompany,
  getCompany,
  getCompanyPage,
  updateCompany,
  type Company,
  type CompanyPage
} from '@urgentiq/typesservices'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface CompanyState {
  company: Company | null;
  companies: Company[] | [];
  companyPage: CompanyPage | null
  isLoading: boolean
  setCompany: (Company: Company) => void
  setCompanies: (Companies: Company[]) => void
  setCompanyPage: (CompanyPage: CompanyPage) => void
  setIsLoading: (isLoading: boolean) => void
}

export const useCompanyStore = create<CompanyState>()(
  devtools((set) => ({
    company: null,
    companies: [],
    companyPage: null,
    isLoading: false,
    setIsLoading: (isLoading: boolean) => set({ isLoading }),
    setCompany: (company: Company) =>
      set({ company }),
    setCompanies: (companies: Company[]) =>
      set({ companies }),
    setCompanyPage: (companyPage: CompanyPage) =>
      set({ companyPage }),
  }))
)

export const useQueryGetCompanyPage = (
  getToken: any,
  pageNumber?: number,
  pageSize?: number,
  name?: string,
  contactName?: string,
  street1?: string,
  street2?: string,
  city?: string,
  stateId?: number,
  stateName?: string,
  zip?: string,
  email?: string,
  phone?: string,
  fax?: string,
  sortColumn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  sortAscending?: boolean
): QueryObserverResult<CompanyPage, unknown> => {
  const { setCompanyPage, setIsLoading } = useCompanyStore()

  return useQuery<CompanyPage>(
    ['company'],
    async () => {
      setIsLoading(true)
      return await getCompanyPage(
        getToken,
        pageNumber,
        pageSize,
        name,
        contactName,
        street1,
        street2,
        city,
        stateId,
        stateName,
        zip,
        email,
        phone,
        fax,
        sortColumn,
        sortAscending)
    },
    {
      onSuccess: (data) => {
        setCompanyPage(data)
        setIsLoading(false)
      },
      onError: (error) => {
        setIsLoading(false)
        throw error
      },
      initialData: {},
    }
  )
}

export const useMutateCreateCompany = (
  getToken: any
): UseMutationResult<Company, unknown, { company: Company }> => {
  const { companies, setCompanies, companyPage, setCompanyPage } = useCompanyStore()

  return useMutation<Company, unknown, { company: Company }>(async ({ company }) => {
    const response = await createCompany(company, getToken)
    const data = (await getCompany(response.id ?? 0, getToken))
    setCompanies([...companies, data])
    const newCompanyPage = { ...companyPage }
    newCompanyPage.items = [...newCompanyPage?.items ?? [], data]
    setCompanyPage(newCompanyPage)
    return data
  })
}

export const useMutateDeleteCompany = (
  getToken: any
): UseMutationResult<Company, unknown, number> => {
  const { companies, setCompanies, companyPage, setCompanyPage } = useCompanyStore()

  return useMutation<Company, unknown, number>(
    async (id: number) => {
      await deleteCompany(id, getToken)
      return { id }
    },
    {
      onSuccess: ({ id }: Company) => {
        const newCompanies = companies.filter((company: Company) => company?.id !== id)
        setCompanies(newCompanies)
        const newCompanyPage = { ...companyPage }
        newCompanyPage.items = newCompanyPage?.items?.filter((company: Company) => company?.id !== id)
        setCompanyPage(newCompanyPage)
      },
    }
  )
}

interface UpdateCompanyMutationPayload {
  id: number;
  company: Partial<Company>;
}

export const useMutateUpdateCompany = (
  getToken: any
): UseMutationResult<Company, unknown, { id: number; company: Company }> => {
  const { companies, setCompanies, companyPage, setCompanyPage } = useCompanyStore()

  return useMutation<Company, unknown, UpdateCompanyMutationPayload>(
    async ({ id, company: partialCompany }) => {
      const company: Company = companyPage?.items?.find((item) => item.id === id) ?? {}
      const newCompany = { ...company, ...partialCompany }
      const newCompanies = companies.map((c) => {
        if (c.id === id) {
          return { ...company, ...partialCompany }
        }
        return c
      })
      setCompanies(newCompanies)
      const newCompanyPage = { ...companyPage }
      newCompanyPage.items = newCompanyPage?.items?.map((c) => {
        if (c.id === id) {
          return { ...company, ...partialCompany }
        }
        return c
      })
      setCompanyPage(newCompanyPage)
      return await updateCompany(id, newCompany, getToken)
    }
  )
}
