import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type Clinic } from '../types/Clinic'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetClinic = fetcher
  .path('/api/Clinic/{clinicId}')
  .method('get')
  .create()

export const getClinic = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetClinic({ clinicId })).data
  } catch (error) {
    if (error instanceof setupGetClinic.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetClinics = fetcher.path('/api/Clinic').method('get').create()

export const getClinics = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetClinics({})).data
  } catch (error) {
    if (error instanceof setupGetClinics.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetClinicDashboard = fetcher
  .path('/api/ClinicDashboard/{clinicId}/{startDate}/{endDate}')
  .method('get')
  .create()

export const getClinicDashboard = async (
  clinicId: number,
  startDate: string,
  endDate: string,
  getToken: any,
  pageNumber?: number,
  pageSize?: number,
  sortColumn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
  sortAscending?: boolean,
  dashboardTab?: 1 | 2 | 3,
  patientNameFilter?: string,
  dateOfBirth?: string,
  isSelfPay?: boolean,
  complaintId?: number,
  visitTypeId?: number,
  staffId?: number,
  roomId?: number,
  providerAccountUserId?: number,
  clinicalProcedureId?: number
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  const queryParams: { 
    pageNumber?: number
    pageSize?: number
    sortColumn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
    sortAscending?: boolean
    dashboardTab?: 1 | 2 | 3
    patientNameFilter?: string
    dateOfBirth?: string
    isSelfPay?: boolean
    complaintId?: number
    visitTypeId?: number
    roomId?: number
    providerAccountUserId?: number
    clinicalProcedureId?: number
    staffId?: number
  } = {}
  if (pageNumber !== undefined) queryParams.pageNumber = pageNumber
  if (pageSize !== undefined) queryParams.pageSize = pageSize
  if (sortColumn !== undefined) queryParams.sortColumn = sortColumn
  if (sortAscending !== undefined) queryParams.sortAscending = sortAscending
  if (dashboardTab !== undefined) queryParams.dashboardTab = dashboardTab
  if (isSelfPay !== undefined) queryParams.isSelfPay = isSelfPay
  if (complaintId !== undefined) queryParams.complaintId = complaintId
  if (visitTypeId !== undefined) queryParams.visitTypeId = visitTypeId
  if (roomId !== undefined) queryParams.roomId = roomId
  if (providerAccountUserId !== undefined) queryParams.providerAccountUserId = providerAccountUserId
  if (clinicalProcedureId !== undefined) queryParams.clinicalProcedureId = clinicalProcedureId
  if (patientNameFilter !== undefined) queryParams.patientNameFilter = patientNameFilter
  if (dateOfBirth !== undefined) queryParams.dateOfBirth = dateOfBirth
  if (staffId !== undefined) queryParams.staffId = staffId
  try {
    return (
      await setupGetClinicDashboard({
        clinicId,
        startDate,
        endDate,
        ...queryParams
      })
    ).data
  } catch (error) {
    if (error instanceof setupGetClinicDashboard.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetPatientProfiles = fetcher
  .path('/api/PatientProfile/{clinicId}/Summary')
  .method('get')
  .create()
export const getPatientProfiles = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientProfiles({ clinicId })).data
  } catch (error) {
    if (error instanceof setupGetPatientProfiles.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/Clinic')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewClinic = async (
  user: Clinic,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await createUser(user)
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteClinic = fetcher
  .path('/api/Clinic/{clinicId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteClinic = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteClinic({ clinicId })
  } catch (error) {
    if (error instanceof setupDeleteClinic.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateClinic = fetcher
  .path('/api/Clinic/{clinicId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateClinic = async (
  clinicId: number,
  clinic: Clinic,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdateClinic({ clinicId, ...clinic })
  } catch (error) {
    if (error instanceof setupUpdateClinic.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
