import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  type UseMutationResult,
  useMutation,
} from '@tanstack/react-query'
import {
  getClinic,
  getClinicDashboard,
  getClinics,
  getPatientProfiles,
  addNewClinic,
  deleteClinic,
  updateClinic,
} from '@/services'
import {
  type Clinic,
  type ClinicDashboard,
  type PatientProfile,
} from '@/types/Clinic'
import { openDB } from 'idb'

interface ClinicState {
  clinicId: number;
  clinic: Clinic | null;
  clinics: Clinic[] | [];
  clinicDashboardData: ClinicDashboard | null;
  setClinicId: (clinicId: number) => void;
  setClinic: (clinic: Clinic) => void;
  setClinics: (clinics: Clinic[]) => void;
  setClinicDashboardData: (data: ClinicDashboard) => void;
}

interface ProfilesState {
  patientProfiles: PatientProfile[] | [];
  setPatientProfiles: (data: PatientProfile[]) => Promise<void>;
}

export const usePatientProfilesStore = create<ProfilesState>()(
  devtools((set) => ({
    patientProfiles: [],
    setPatientProfiles: async (
      patientProfiles: PatientProfile[]
    ): Promise<void> => {
      try {
        const db = await openDB('patientProfiles-db', 1, {
          upgrade(db) {
            db.createObjectStore('patientProfiles-store')
          },
        })
        const tx = db.transaction('patientProfiles-store', 'readonly')
        const existingData = await tx
          .objectStore('patientProfiles-store')
          .get('patientProfiles')
        if (!existingData) {
          const writeTx = db.transaction('patientProfiles-store', 'readwrite')
          await writeTx
            .objectStore('patientProfiles-store')
            .put(patientProfiles, 'patientProfiles')
          await writeTx.done
          set({ patientProfiles })
          return
        }
        set({ patientProfiles: existingData })
      } catch (error) {
        console.error(error)
      }
    },
  }))
)

export const useClinicStore = create<ClinicState>()(
  devtools(
    persist(
      (set) => ({
        clinicId: 0,
        clinic: null,
        clinics: [],
        clinicDashboardData: null,
        setClinicId: (clinicId: number) => set({ clinicId }),
        setClinic: (clinic: Clinic) => set({ clinic }),
        setClinics: (clinics: Clinic[]) => set({ clinics }),
        setClinicDashboardData: (data: ClinicDashboard) =>
          set({ clinicDashboardData: data }),
      }),
      {
        name: 'clinic',
      }
    )
  )
)

export const useQueryGetClinic = (
  clinicId: number,
  getToken: any
): QueryObserverResult<Clinic, unknown> => {
  const { setClinic } = useClinicStore()

  return useQuery<Clinic>(
    ['clinic', clinicId],
    async () => await getClinic(clinicId, getToken),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setClinic(data)
      },
    }
  )
}

export const useQueryGetClinics = (
  getToken: any
): QueryObserverResult<Clinic[], unknown> => {
  const { setClinics } = useClinicStore()

  return useQuery<Clinic[]>(
    ['clinics'],
    async () => await getClinics(getToken),
    {
      onSuccess: (data) => {
        setClinics(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetClinicDashboard = (
  clinicId: number,
  startDate: string,
  endDate: string,
  getToken: any,
  pageNumber?: number,
  pageSize?: number,
  sortColumn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
  sortAscending?: boolean,
  dashboardTab?: 1 | 2 | 3,
  patientNameFilter?: string,
  dateOfBirth?: string,
  isSelfPay?: boolean,
  complaintId?: number,
  visitTypeId?: number,
  staffId?: number,
  roomId?: number,
  providerAccountUserId?: number,
  clinicalProcedureId?: number

): QueryObserverResult<ClinicDashboard, unknown> => {
  const { setClinicDashboardData } = useClinicStore()

  return useQuery<ClinicDashboard>(
    [
      'clinic-dashboard',
      clinicId,
      startDate,
      endDate,
      pageNumber,
      pageSize,
      sortColumn,
      sortAscending,
      dashboardTab,
      patientNameFilter,
      dateOfBirth,
      isSelfPay,
      complaintId,
      visitTypeId,
      staffId,
      roomId,
      providerAccountUserId,
      clinicalProcedureId
    ],
    async () =>
      await getClinicDashboard(
        clinicId,
        startDate,
        endDate,
        getToken,
        pageNumber,
        pageSize,
        sortColumn,
        sortAscending,
        dashboardTab,
        patientNameFilter,
        dateOfBirth,
        isSelfPay,
        complaintId,
        visitTypeId,
        staffId,
        roomId,
        providerAccountUserId,
        clinicalProcedureId
      ),
    {
      enabled: !!clinicId && !!startDate && !!endDate,
      refetchInterval: 10000,
      onSuccess: (data) => {
        setClinicDashboardData(data)
      },
    }
  )
}

export const useQueryGetPatientProfiles = (
  clinicId: number,
  getToken: any
): QueryObserverResult<PatientProfile[], unknown> => {
  const { setPatientProfiles } = usePatientProfilesStore()

  return useQuery<PatientProfile[]>(
    ['patient-profile-list', clinicId],
    async () => await getPatientProfiles(clinicId, getToken),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setPatientProfiles(data).catch((err) => {
          throw err
        })
      },
      initialData: [],
    }
  )
}

export const useMutateAddNewClinic = (
  getToken: any
): UseMutationResult<Clinic[], unknown, { clinic: Clinic }> => {
  const { clinics, setClinics } = useClinicStore()

  return useMutation<Clinic[], unknown, { clinic: Clinic }>(
    async ({ clinic }) => await addNewClinic(clinic, getToken),
    {
      onSuccess: (data) => {
        setClinics([...clinics, data as Clinic])
      },
    }
  )
}

export const useMutateDeleteClinic = (
  getToken: any
): UseMutationResult<any, unknown, { clinicId: number }> => {
  const { clinics, setClinics } = useClinicStore()

  return useMutation<any, unknown, { clinicId: number }>(
    async ({ clinicId }) => await deleteClinic(clinicId, getToken),
    {
      onSuccess: (data) => {
        const newClinics = clinics.filter(
          (clinic: Clinic) => clinic?.id !== data?.id
        )
        setClinics(newClinics)
      },
    }
  )
}

export const useMutateUpdateClinic = (
  getToken: any
): UseMutationResult<Clinic, unknown, { clinicId: number; clinic: Clinic }> => {
  const { clinics, setClinics } = useClinicStore()

  return useMutation<Clinic, unknown, { clinicId: number; clinic: Clinic }>(
    async ({ clinicId, clinic }) =>
      await updateClinic(clinicId, clinic, getToken),
    {
      onSuccess: (data) => {
        const newClinics = clinics.map((c: Clinic) =>
          c?.id === data?.id ? data : c
        )
        setClinics(newClinics)
      },
    }
  )
}
