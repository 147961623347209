import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getOccMedReportXLSX,
} from '@urgentiq/typesservices'
import { getOccMed, addOccMed } from '@/services'
import { type OccMed } from '@/types'
import { isEmpty } from '@/utility'

interface OccMedState {
  occMed: OccMed | null;
  setOccMed: (occMed: OccMed) => void;
}

export const useOccMedStore = create<OccMedState>()(
  devtools((set) => ({
    occMed: null,
    setOccMed: (occMed: OccMed) => set({ occMed }),
  }))
)

export const useQueryGetOccMed = (
  visitId: number,
  getToken: any
): QueryObserverResult<OccMed, unknown> => {
  const { setOccMed } = useOccMedStore()

  return useQuery<OccMed>(
    ['occ-med'],
    async () => await getOccMed(visitId, getToken),
    {
      enabled: !!visitId,
      onSuccess: (data) => {
        setOccMed(data)
        return data
      },
    }
  )
}

export const useMutateAddOccMed = (
  getToken: any
): UseMutationResult<OccMed, unknown, { visitId: number; occMed: OccMed }> => {
  const { setOccMed } = useOccMedStore()

  return useMutation<OccMed, unknown, { visitId: number; occMed: OccMed }>(
    async ({ visitId, occMed }) => {
      if (!visitId || isEmpty(occMed)) return
      return await addOccMed(visitId, occMed, getToken)
    },
    {
      onSuccess: (data) => {
        setOccMed(data)
        return data
      },
    }
  )
}

export const useQueryGetOccMedReportXLSXBlob = (
  getToken: any
): UseMutationResult<Blob, unknown, { startDate: string, endDate: string, clinicId?: number }> => {

  return useMutation<Blob, unknown, { startDate: string, endDate: string, clinicId?: number }>(
    ['occ-med'],
    async ({ startDate, endDate, clinicId }) => {
      return await (
        await getOccMedReportXLSX(getToken, startDate, endDate, clinicId)
      ).blob()
    },
    {
      onSuccess: (data) => {
        return data
      },
    }
  )
}
